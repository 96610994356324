<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Templates</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Templates"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="accent"
                v-on="on"
                class="mr-2"
                @click="$refs.templateDialog.open()"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add Template</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                light
                v-on="on"
                :to="{ name: 'module-graham-templates-archived' }"
              >
                <v-icon small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>View Archived Templates</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="templates"
        :disable-pagination="true"
        :hide-default-footer="true"
        no-data-text="There are currently no templates"
      >
        <template v-slot:item.type="{ item }">
          <v-chip
            label
            v-if="item.type === 'Small Domestic'"
            color="green"
            text-color="white"
            >Small Domestic</v-chip
          >
          <v-chip label v-else-if="item.type === 'Contract'" color="blue"
            >Contract</v-chip
          >
          <v-chip
            label
            color="orange"
            v-else-if="item.type === 'Commercial/Large Domestic'"
            >Commercial/Large Domestic</v-chip
          >
        </template>
        <template v-slot:item.price="{ item }">
          £{{ formatPrice(item.price) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.templateDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>

    <template-dialog ref="templateDialog" />

    <v-dialog v-model="deleteTemplate.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Template</v-card-title>
        <v-card-text
          >Are you sure you wish to delete this template?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteTemplate.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TemplateDialog from "./components/TemplateDialog.vue";

export default {
  components: {
    TemplateDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Templates",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Title", value: "title", width: "20%" },
        { text: "Description", value: "description", width: "40%" },
        { text: "Price", value: "price" },
        { text: "Type", value: "type" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteTemplate: {
        dialog: false,
        template: {},
        loading: false,
      },
    };
  },

  computed: {
    templates() {
      let templates = this.$store.state.graham.templates["templates"];

      if (this.searchTerm !== "") {
        templates = templates.filter((p) => {
          const title = p.title.toLowerCase();
          const description = p.description ? p.description.toLowerCase() : "";
          const type = p.type.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            title.includes(searchTerm) ||
            description.includes(searchTerm) ||
            type.includes(searchTerm)
          );
        });
      }

      return templates;
    },
  },

  methods: {
    formatPrice(value) {
      return parseFloat(value).toFixed(2);
    },

    openDelete(template) {
      this.deleteTemplate.template = template;
      this.deleteTemplate.dialog = true;
    },

    resetDelete() {
      this.deleteTemplate.dialog = false;
      this.deleteTemplate.template = {};
      this.deleteTemplate.loading = false;
    },

    saveDelete() {
      this.deleteTemplate.loading = true;

      this.$store
        .dispatch("graham/templates/deleteTemplate", {
          appId: this.$route.params.id,
          templateId: this.deleteTemplate.template.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteSolicitor.loading = false;
        });
    },
  },
};
</script>
